require('@babel/polyfill');

require('core-js/features/promise');
require('core-js/features/url-search-params');

// fetch() polyfill for making API calls.
require('isomorphic-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

if (typeof window !== 'undefined') {
  require('matchmedia-polyfill');
  require('matchmedia-polyfill/matchMedia.addListener');
}
